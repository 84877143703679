<template>
  <v-container class="pa-0">
    <div class="logo">
      <div>
        <v-img
          src="@/assets/images/logos/logo_roxo.png"
          contain
          id="imageLogo"
        />
      </div>
    </div>

    <Step1 v-show="step == 1" />
    <Step2 v-show="step == 2" />
    <Step3 v-show="step == 3" />
    <Step4 v-show="step == 4" />
    <Step5 v-show="step == 5" />
  </v-container>
</template>
<script>
import Step1 from "@/components/onboarding/sections/Step1";
import Step2 from "@/components/onboarding/sections/Step2";
import Step3 from "@/components/onboarding/sections/Step3";
import Step4 from "@/components/onboarding/sections/Step4";
import Step5 from "@/components/onboarding/sections/Step5";

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },

  computed: {
    step() {
      return this.$store.getters["onboarding/step"];
    },
  },
};
</script>

<style  lang="sass">
.brand-name
  color: #624DE3

/* desktop */
@media (min-width: 960px)

  #imageLogo
    width: 200px
    height: 30px

  .container
    height: 100%
  .logo
    justify-content: center
    display: flex
    margin-top: 52px

/* mobile*/
@media (max-width: 960px)
  .logo
    justify-content: center
    display: flex
    margin-top: 40px

  #imageLogo
    width: 144px
    height: 22px

  .container
    height: 1%
</style>

 