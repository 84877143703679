<template>
  <div>
    <div class="d-flex flex-column justify-space-between align-center mt-16">
      <div class="row">
        <div class="col d-flex justify-center">
          <img src="@/assets/images/onboarding/01.png" />
        </div>
      </div>
      <div id="header-group" class="text-center">
        <div id="header" class="mt-14 mb-3 px-5">
          <h1 class="d-inline">Olá {{ name }}, seja bem vindo(A) ao</h1>
          <h1 class="d-inline primary--text pl-1">psicoplanner!</h1>
        </div>
        <div id="sub-header">
          Para lhe oferecer uma melhor experiência precisamos de sua ajuda nas
          configurações a seguir.
        </div>
      </div>
    </div>

    <v-row class="text-center mt-15">
      <v-col>
        <v-btn @click="next()" rounded color="primary" dark> começar </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    name() {
      // return two first names
      return this.user.name.split(" ").slice(0, 2).join(" ");
    }
  },
  methods: {
    next() {
      this.$store.commit("onboarding/nextStep");
    },
  },
};
</script>
<style scoped lang='sass'>
/* desktop */
@media (min-width: 960px)
  #header-group
    width: 624px

  #header
    width: 575px

  #sub-header
    font-size: 20px
    font-family: "Poppins"
    font-style: normal
    font-weight: 300
    font-size: 20px
    line-height: 32px
    text-align: center
    letter-spacing: 0.25px
    color: #4e4b59

/* mobile*/
@media (max-width: 960px)
  img
    width: 230px
    height: 180

  #header-group
    // width: 355px

  #header
    width: 355px

    font-family: 'Poppins'
    font-style: normal
    font-weight: 700
    font-size: 13px
    line-height: 32px

    // text-align: center
    // color: #33303E
    // flex: none
    // order: 0
    // align-self: stretch
    // flex-grow: 0

  #sub-header
    width: 329px
    height: 72px
    font-family: 'Poppins'
    // font-style: normal
    // font-weight: 400
    // font-size: 16px
    // line-height: 24px

    text-align: center
    // letter-spacing: 0.5px

    // color: #4E4B59
    // flex: none
    // order: 1
    // flex-grow: 0
</style>

 