<template>
  <div>
    <StepsContainer>
      <template v-slot:header>
        <span class="d-md-inline">Como você vai usar o </span>
        <span class="d-md-inline primary--text">psicoplanner ?</span>
      </template>

      <template v-slot:sub-header>
        Selecione uma ou mais opções, iremos personalizar sua experiência com o
        sistema
      </template>

      <template v-slot:options>
        <v-row class="d-flex justify-center" style="width: 100%">
          <template v-for="(option, index) in options">
            <v-col md="4">
              <StepCardOption v-model="company_type" :option="option" />
            </v-col>
          </template>
        </v-row>
      </template>

      <template v-slot:buttons>
        <div>
          <v-btn class="mr-4" @click="back()" rounded color="primary" outlined>
            <v-icon left> mdi-chevron-left </v-icon>
            voltar
          </v-btn>
          <v-btn class="ml-4" @click="next()" rounded color="primary" dark>
            proximo
            <v-icon right> mdi-chevron-right </v-icon>
          </v-btn>
        </div>

        <div class="d-flex justify-center pt-5">
          <v-icon v-for="i in 4" size="8px" class="pa-1" color="primary" :style="{ opacity: i == step - 1 ? 1 : 0.5 }">
            mdi-circle
          </v-icon>
        </div>
      </template>
    </StepsContainer>
  </div>
</template>

<script>
import StepsContainer from "./StepsContainer.vue";
import StepCardOption from "@/components/onboarding/ui/StepCardOption.vue";

export default {
  components: {
    StepsContainer,
    StepCardOption,
  },
  data() {
    return {
      company_type: null,
      options: [
        {
          icon: "smile",
          text: "Só para mim",
          value: "individual",
        },
        {
          icon: "group",
          text: "Eu e atendente/secretaria",
          value: "individual_plus",
        },
        {
          icon: "building",
          text: "Clínica com vários profissionais",
          value: "clinic",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    company() {
      return this.$store.state.auth.company;
    },
    step() {
      return this.$store.state.onboarding.step;
    },
  },
  methods: {
    next() {
      this.$loading.start();
      this.$http
        .update("company/company", this.company.id, {
          company_type: this.company_type,
        })
        .then((response) => {
          this.$loading.finish();
          this.$auth.getLoginData();

          this.$store.commit("onboarding/nextStep");
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    back() {
      this.$store.commit("onboarding/backStep");
    },
  },
};
</script>
