<template>
  <div>
    <StepsContainer>
      <template v-slot:header>
        <span class="d-md-inline">Como gostaria de chamar o seu cliente?</span>
      </template>

      <template v-slot:sub-header>
        Quase lá, falta pouco pra que você possa aproveitar todos os recursos que
        preparamos para você
      </template>

      <template v-slot:options>
        <v-row class="d-flex justify-center" style="width: 100%">
          <template v-for="(option, index) in options">
            <v-col md="4">
              <StepCardOption v-model="customer_alias" :option="option" />
            </v-col>
          </template>
        </v-row>
      </template>

      <template v-slot:buttons>
        <div>
          <v-btn class="mr-4" @click="back()" rounded color="primary" outlined>
            <v-icon left> mdi-chevron-left </v-icon>
            voltar
          </v-btn>
          <v-btn class="ml-4" @click="next()" rounded color="primary" dark>
            proximo
            <v-icon right> mdi-chevron-right </v-icon>
          </v-btn>
        </div>

        <div class="d-flex justify-center pt-5">
          <v-icon v-for="i in 4" size="8px" class="pa-1" color="primary" :style="{ opacity: i == step - 1 ? 1 : 0.5 }">
            mdi-circle
          </v-icon>
        </div>
      </template>
    </StepsContainer>
  </div>
</template>

<script>
import StepsContainer from "./StepsContainer.vue";
import StepCardOption from "@/components/onboarding/ui/StepCardOption.vue";

export default {
  components: {
    StepsContainer,
    StepCardOption,
  },
  data() {
    return {
      customer_alias: 'customer',
      options: [
        {
          icon: "add-user",
          text: "Cliente",
          value: "customer",
        },
        {
          icon: "add-user-1",
          text: "Paciente",
          value: "patient",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    company() {
      return this.$store.state.auth.company;
    },
    step() {
      return this.$store.state.onboarding.step;
    },
  },
  methods: {
    next() {
      this.$loading.start();
      this.$http
        .update("company/company", this.company.id, {
          customer_alias: this.customer_alias,
        })
        .then((response) => {
          this.$loading.finish();
          this.$auth.getLoginData();

          this.$store.commit("onboarding/nextStep");
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    back() {
      this.$store.commit("onboarding/backStep");
    },
  },
};
</script>
 