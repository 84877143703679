<template>
  <div
    @click="handleInput()"
    class="card"
    :style="isSelected ? 'border-color: #624DE3' : 'border-color: #E0E0E0'"
  >
    <div class="card-action">
      <v-img class="card-icon" :src="iconPath" />
      <v-checkbox
        color="primary"
        v-model="intent"
        @change="handleInput()"
        :value="option.value"
      />
    </div>
    <div class="card-text">
      {{ option.text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },

    value: {},
  },

  data() {
    return {
      intent: null,
    };
  },

  created() {
    this.setValue(this.value);
  },

  computed: {
    intentIsArray() {
      return Array.isArray(this.intent);
    },
    isSelected() {
      if (this.intentIsArray) {
        return this.intent.includes(this.option.value);
      }
      return this.intent === this.option.value;
    },
    iconPath() {
      return process.env.BASE_URL + "app/icons/" + this.option.icon + ".svg";
    },
  },

  watch: {
    value(val) {
      this.setValue(val);
    },
  },

  methods: {
    setValue(val) {
      if (val) {
        this.intent = val;
      }
    },

    handleInput(event) {
      if (this.intentIsArray) {
        if (this.isSelected) {
          const index = this.intent.indexOf(this.option.value);
          if (index > -1) {
            this.intent.splice(index, 1);
          }
        } else {
          this.intent.push(this.option.value);
        }
      }

      if (!this.intentIsArray) {
        if (this.isSelected) {
          this.intent = null;
        } else {
          this.intent = this.option.value;
        }
      }
      this.$emit("input", this.intent);
    },
  },
};
</script>

<style lang="sass" scoped>
  /* desktop */
@media (min-width: 960px)
  .card
    box-sizing: border-box
    height: 160px
    background: #FFFFFF
    border: 1.5px solid #EBEBF5
    border-radius: 32px
    max-width: 360px

  .card-icon
    max-width: 48px

  .card-action
    margin-top: 24px
    margin-left: 24px
    margin-right: 24px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    height: 48px

  .card-text
    margin-top: 24px
    margin-left: 24px
    margin-right: 24px
    height: 24px
    font-family: 'Poppins'
    font-style: normal
    font-weight: 700
    font-size: 16px
    line-height: 24px

    align-items: center
    letter-spacing: 0.5px
    color: #4E4B59

/* mobile*/
@media (max-width: 960px)
  .card
    box-sizing: border-box
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 16px
    gap: 16px
    width: 160px
    height: 120px
    background: #FFFFFF
    border: 1.5px solid #EBEBF5
    border-radius: 24px
    flex: none
  .card-icon
    max-width: 24px

  .card-action
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 0px
    gap: 16px
    height: 32px
    flex: none
    order: 0
    align-self: stretch
    flex-grow: 0

  .card-text
    width: 138px
    height: 40px
    font-style: normal
    font-weight: 700
    font-size: 12px
    line-height: 20px
    display: flex
    align-items: center
    letter-spacing: 0.4px
    flex: none
    order: 1
    align-self: stretch
    flex-grow: 0
</style>