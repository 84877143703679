<template>
  <div>
    <StepsContainer>
      <template v-slot:header>
        <span class="d-md-inline">Quantos pacientes você tem?</span>
      </template>

      <template v-slot:sub-header>
        Último passo para que você aproveitar 100% do sistema que otimizará a sua
        rotina
      </template>
      <template v-slot:options>
        <v-row class="d-flex justify-center" style="width: 100%">
          <template v-for="(option, index) in options">
            <v-col md="5">
              <StepCardOption v-model="customers_quantity" :option="option" />
            </v-col>
          </template>
        </v-row>
      </template>

      <template v-slot:buttons>
        <div>
          <v-btn class="mr-4" @click="back()" rounded color="primary" outlined>
            <v-icon left> mdi-chevron-left </v-icon>
            voltar
          </v-btn>
          <v-btn class="ml-4" @click="next()" rounded color="primary" dark>
            proximo
            <v-icon right> mdi-chevron-right </v-icon>
          </v-btn>
        </div>

        <div class="d-flex justify-center pt-5">
          <v-icon v-for="i in 4" size="8px" class="pa-1" color="primary" :style="{ opacity: i == step - 1 ? 1 : 0.5 }">
            mdi-circle
          </v-icon>
        </div>
      </template>
    </StepsContainer>
  </div>
</template>

<script>
import StepsContainer from "./StepsContainer.vue";
import StepCardOption from "@/components/onboarding/ui/StepCardOption.vue";

export default {
  components: {
    StepsContainer,
    StepCardOption,
  },
  data() {
    return {
      customers_quantity: null,

      options: [
        {
          icon: "contagem-1",
          text: "0 a 5",
          value: "0-5",
        },
        {
          icon: "contagem-2",
          text: "6 a 10",
          value: "6-10",
        },
        {
          icon: "contagem-3",
          text: "mais de 10",
          value: "+10",
        },
        {
          icon: "book-open",
          text: "Sou estudante",
          value: "student",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    company() {
      return this.$store.state.auth.company;
    },
    step() {
      return this.$store.state.onboarding.step;
    },
  },
  methods: {
    next() {
      this.$loading.start();
      this.$http
        .update("company/company", this.company.id, {
          customers_quantity: this.customers_quantity,
        })
        .then((response) => {
          this.$loading.finish();
          this.$auth.getLoginData();

          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    back() {
      this.$store.commit("onboarding/backStep");
    },
  },
};
</script>
<style scoped lang="sass">
  /* desktop */
@media (min-width: 960px)
  .col-md-5
    max-width: 35%
</style>